@import url('https://fonts.googleapis.com/css2?family=Lugrasimo:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto');


body {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  /* width: 100vw; */
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 2rem 0rem 2rem;
}


.title {
  font-family: 'Lugrasimo';
}

.top-notice {
  background-color: red;
  color: white;
  font-size: 0.9rem;
  padding: 0.8rem;
  text-align: center;
}

.logo {
  /* position: absolute;
  top: 2rem;
  right: 2rem; */
  width: 12%;
  min-width: 140px;
}

.logo>img {
  width: 100%;
}

.menuButton {
  border: 1px solid rgb(60, 60, 60);
  border-radius: 5px;
  padding: 0.5rem;
  transition: all 300ms;
}

.menuButton:hover {
  background-color: rgb(60, 60, 60);
  color: white;
}

.menuButton:active {
  background-color: rgb(94, 94, 94);
}

button {
  background-color: white;
  color: red;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border: 1px solid red;
  border-radius: 10px;
  transition: all 300ms;
}

button:hover {
  background-color: red;
  color: white;
  transform: scale(1.05);
}

button:active {
  background-color: rgb(255, 96, 96);
  transform: scale(1);
}

button.secondary {
  background-color: red;
  color: white;
}

div.main {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  max-width: 800px;
  margin: auto;
  height: calc(100vh - 150px);
}

.main>h1 {
  font-family: "Lugrasimo";
  color: red;
  border-bottom: 4px solid red;
  margin-bottom: 0px;
}

.main>p {
  color: rgb(136, 136, 136);
  line-height: 1.7rem;
  font-size: 1.2rem;
  margin-bottom: 2.5rem;
}

.main>p>b {
  color: rgb(94, 94, 94);
  font-weight: 900;
}

h1.special {
  font-size: 4rem;
  margin: 0px;
  margin-top: -1rem;
  margin-bottom: 2.5rem;
  font-weight: 900;
  font-family: Arial, Helvetica, sans-serif;
}

.services {
  position: relative;
  background-color: red;
  padding: 4rem 0rem;
  color: white;
  display: flex;
  text-align: center;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
}

.service {
  max-width: 300px;
  padding: 1rem;
  margin: 1rem;
  transition: all 300ms;
}

.service>.icon {
  font-size: 5rem;
}

.service>.title {
  font-size: 2rem;
  font-weight: 900;
  text-transform: uppercase;
  margin: 1rem auto;
}

.service:hover {
  background-color: rgb(203, 0, 0);
  border-radius: 1rem;
  transform: scale(1.05);
}

.speciality {
  text-align: center;
  margin: 2rem;
}

.speciality-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.speciality-item>img {
  width: 70vw;
  height: 70vw;
  max-height: 350px;
  max-width: 350px;
  object-fit: cover;
  margin: 2rem;
  border-radius: 50%;
  transition: all 300ms;
}

.speciality-item>img:hover {
  transform: scale(1.1);
}

.speciality-item>h2 {
  margin-top: 0rem;
  margin-bottom: 3rem;
  cursor: default;
  transition: all 400ms;
}

.speciality-item>h2:hover {
  color: red;

}



footer {
  background: rgb(60, 60, 60);
  color: white;
}

.footer-top {
  display: flex;
  flex-wrap: wrap;
  margin: 0rem 2rem;
  justify-content: space-around;

}

.footer-top>div {

  width: 300px;
  padding: 3rem 0rem;

}

footer li {
  list-style: none;
  margin: 10px 0px;
  transition: all 300ms;
  cursor: default;
}

footer li:hover {
  color: red;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: rgb(44, 44, 44);
  padding: 2rem;

}

.social-links>a {
  padding: 0.6rem;
  background-color: rgb(100, 100, 100);
  margin: 5px;
  border-radius: 10%;

}

.social-links>a:hover {
  background-color: rgb(136, 136, 136);
}




a {
  text-decoration: none;
  color: inherit;
  font-weight: 300;
}


@media only screen and (max-width: 665px) {
  .footer-top {
    flex-direction: column;
    align-items: center;
  }

  .footer-bottom {
    flex-direction: column;
  }

  .footer-bottom>.social-links {
    margin: 2rem;
  }
}



.booking {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 2rem 0rem;
  /* align-items: center; */
}

.booking-info {
  width: 100%;
  max-width: 400px;
  margin: 1rem;
}

.booking-phone {
  text-align: center;
  background-color: red;
  color: white;
  padding: 1rem;
  cursor: default;
  transition: all 300ms;
}

.booking-phone:hover {
  transform: scale(1.05);
  border-radius: 1rem;
  /* border: 5px solid black; */

}

.booking-input {
  margin: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
}

.booking-input>input {
  margin: 1rem 0rem;

  font-size: 1.1rem;
  /* width: 500px; */
}

input[type="date"],
input[type="time"] {
  min-width: 92%;
  margin: 1rem auto;
}


input {
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid black;
  background-color: white;

}

.lahori-image {
  background-color: black;
  padding: 10rem 2rem;
  display: flex;
  justify-content: space-around;
  color: white;
  flex-wrap: wrap;
  position: relative;
}

.lahori-image>img {
  width: 100%;
  max-width: 500px;
  /* border: 1px solid white; */
  border-radius: 1rem;
  object-fit: cover;
  transition: all 300ms;
}

.lahori-image>img:hover {
  transform: scale(1.1);
}

.delivery-homepage {
  text-align: center;
  padding: 5rem 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.delivery-homepage>img {
  border-radius: 3rem;
  max-width: 200px;
  margin-top: 2rem;
  transition: all 300ms;
}

.delivery-homepage>img:hover {
  transform: scale(1.1);
}

.delivery-homepage>img:active {
  transform: scale(1);
}

@media only screen and (max-width: 665px) {
  .lahori-image {
    flex-direction: column-reverse;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .lahori-image>img {
    margin-bottom: 2rem;
  }
}

.custom-shape-divider-top-1703116172 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1703116172 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 112px;
}

.custom-shape-divider-top-1703116172 .shape-fill {
  fill: #FFFFFF;
}

.custom-shape-divider-bottom-1703116588 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1703116588 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 112px;
}

.custom-shape-divider-bottom-1703116588 .shape-fill {
  fill: #FFFFFF;
}

.custom-shape-divider-bottom-1703116722 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1703116722 svg {
  position: relative;
  display: block;
  width: calc(122% + 1.3px);
  height: 52px;
}

.custom-shape-divider-bottom-1703116722 .shape-fill {
  fill: #FFFFFF;
}

.custom-shape-divider-top-1703116812 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1703116812 svg {
  position: relative;
  display: block;
  width: calc(122% + 1.3px);
  height: 52px;
}

.custom-shape-divider-top-1703116812 .shape-fill {
  fill: #FFFFFF;
}

.custom-shape-divider-bottom-1703116984 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1703116984 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 25px;
}

.custom-shape-divider-bottom-1703116984 .shape-fill {
  fill: #FFFFFF;
}

.custom-shape-divider-top-1703117042 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-top-1703117042 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 25px;
}

.custom-shape-divider-top-1703117042 .shape-fill {
  fill: #FFFFFF;
}